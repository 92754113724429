import React from 'react'
import Home1 from '../components/home1'
import Home2 from '../components/home2'
import Timeline from '../components/timeline' 

function HomeFinal() {
  return (
    <div>
      <Home1 />
      <Home2 />
      <Timeline />
    </div>
  )
}

export default HomeFinal
import React from 'react'
import '../styles/sponsor.css'

function Sponsor() {
  return (
    <div className='Sponsor'>
        <h1> GRATITUDE EXTENDED TO OUR SPONSORS </h1>
        <div className='sponsorContent'>

        </div>
    </div>
  )
}

export default Sponsor
import React from 'react'
import '../styles/contact.css'
import "../styles/font.css";

function Contact() {
  return (
    <div className='Contact'>
      {/* <div className='Title'>
        <h1> Contact Us </h1>
      </div> */}
      <div className='SubContact'>
        <h2> DELEGATES AND ADMINISTRATION </h2>
        <p> For any inquiries regarding registration, please contact </p>
        <div className='ContactInfo'>
        <div className='Left'> 
          <h3> Roes Devina Syarla Anggana </h3>
          <h4> <em> USG of Delegate Relations </em> </h4>
          <p> Line ID : rdavinasyarlaa </p>
          <p> Whatsapp Number : 085767651073 </p>
        </div>
        <div className='Right'>
          <h3> Nasha Nasmia </h3>
          <h4> <em> Director of Administration </em> </h4>
          <p> Line ID : nashanasmia </p>
          <p> Whatsapp Number : 082122847040 </p>
        </div>
        </div>
      </div>
      <div className='SubContact'>
        <h2> SPONSORSHIP AND PARTNERSHIP </h2>
        <p> For any inquiries regarding sponsorship and partnership, please contact </p>
        <div className='ContactInfo'>
        <div className='Left'> 
          <h3> Nova Nathania Muljohartono </h3>
          <h4> <em> USG of Finance </em> </h4>
          <p> Line ID : novanathaniaaa </p>
          <p> Whatsapp Number : 089621166679 </p>
        </div>
        <div className='Right'>
          <h3> Theophillus Mangatas Christanto </h3>
          <h4> <em> Director of Sponsorship and Partnership </em> </h4>
          <p> Line ID : theophillusm </p>
          <p> Whatsapp Number : 081284554065 </p>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Contact